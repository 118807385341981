.Container{
    height: 100%;
    width: 100%;
    position: relative;
}

.Content{
    width: 23vw;
    height: calc(23vw/2.5);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

@media (max-aspect-ratio: 7/5){
    .Content{
        width: 30vw;
        height: calc(30vw/2.5);
    }
}


@media (max-aspect-ratio: 1/1){
    .Content{
        width: 40vw;
        height: calc(40vw/2.5);
    }
}

@media (max-aspect-ratio: 5/7){
    .Content{
        width: 70vw;
        height: calc(70vw/2.5);
    }
}